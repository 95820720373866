@use 'devices';
@use 'typography';

@import 'normalize.css/normalize.css';

:root {
    font-size: 62.5%;

    --cinemo-colors-black-100: #efeff4;
    --cinemo-colors-black-150: #d9d9df;
    --cinemo-colors-black-200: #c1c2cb;
    --cinemo-colors-black-300: #acadb6;
    --cinemo-colors-black-400: #9495a0;
    --cinemo-colors-black-500: #777987;
    --cinemo-colors-black-600: #61636d;
    --cinemo-colors-black-700: #494a56;
    --cinemo-colors-black-800: #22232a;
    --cinemo-colors-black-850: #17181e;
    --cinemo-colors-black-900: #0a0b11;

    --cinemo-colors-accent-150: #d7ecff;
    --cinemo-colors-accent-200: #afd9ff;
    --cinemo-colors-accent-300: #5db1ff;
    --cinemo-colors-accent-400: #4c92ea;
    --cinemo-colors-accent-500: #4c92ea;
    --cinemo-colors-accent-600: #2a54bf;
    --cinemo-colors-accent-700: #1834aa;
    --cinemo-colors-accent-800: #061494;
    --cinemo-colors-accent-900: #010a5f;

    --cinemo-colors-green-150: #e5eee3;
    --cinemo-colors-green-200: #ccdfc7;
    --cinemo-colors-green-300: #adcba5;
    --cinemo-colors-green-400: #8ab57f;
    --cinemo-colors-green-500: #679f59;
    --cinemo-colors-green-600: #448833;
    --cinemo-colors-green-700: #306723;
    --cinemo-colors-green-750: #2a5420;
    --cinemo-colors-green-800: #24401d;
    --cinemo-colors-green-850: #1e2c1a;

    --cinemo-colors-yellow-200: #f2e2c8;
    --cinemo-colors-yellow-500: #da9b39;
    --cinemo-colors-yellow-transparent-10: rgba(218, 155, 57, 0.1);

    --cinemo-colors-red-200: #f7b4a5;
    --cinemo-colors-red-400: #f0694c;
    --cinemo-colors-red-500: #ec441f;
    --cinemo-colors-red-600: #bd3619;
    --cinemo-colors-red-700: #8e2913;

    --cinemo-colors-white-transparent-05: rgba(255, 255, 255, 0.05);
    --cinemo-colors-white-transparent-10: rgba(255, 255, 255, 0.1);
    --cinemo-colors-white-transparent-20: rgba(255, 255, 255, 0.2);
    --cinemo-colors-white-transparent-30: rgba(255, 255, 255, 0.3);
    --cinemo-colors-white-transparent-40: rgba(255, 255, 255, 0.4);
    --cinemo-colors-white-transparent-50: rgba(255, 255, 255, 0.5);
    --cinemo-colors-white-transparent-60: rgba(255, 255, 255, 0.6);
    --cinemo-colors-white-transparent-70: rgba(255, 255, 255, 0.7);
    --cinemo-colors-white-transparent-80: rgba(255, 255, 255, 0.8);
    --cinemo-colors-white-transparent-90: rgba(255, 255, 255, 0.9);

    --cinemo-colors-black-transparent-05: rgba(0, 0, 0, 0.05);
    --cinemo-colors-black-transparent-10: rgba(0, 0, 0, 0.1);
    --cinemo-colors-black-transparent-20: rgba(0, 0, 0, 0.2);
    --cinemo-colors-black-transparent-30: rgba(0, 0, 0, 0.3);
    --cinemo-colors-black-transparent-40: rgba(0, 0, 0, 0.4);
    --cinemo-colors-black-transparent-50: rgba(0, 0, 0, 0.5);
    --cinemo-colors-black-transparent-60: rgba(0, 0, 0, 0.6);
    --cinemo-colors-black-transparent-70: rgba(0, 0, 0, 0.7);
    --cinemo-colors-black-transparent-80: rgba(0, 0, 0, 0.8);
    --cinemo-colors-black-transparent-90: rgba(0, 0, 0, 0.9);

    --cinemo-colors-accent-transparent-30: rgba(93, 177, 255, 0.3);

    --cinemo-colors-warning-10: rgba(218, 155, 57, 0.1);

    --cinemo-gradient-brand-accent: linear-gradient(
        to left,
        #547ae8,
        var(--cinemo-colors-accent-300),
        #60d0f6,
        var(--cinemo-colors-accent-300),
        #547ae8
    );
    --cinemo-gradient-gray-background: linear-gradient(
        0deg,
        var(cinemo-colors-black-900) 50.06%,
        var(cinemo-colors-black-800) 23.31%
    );
    --cinemo-gradient-light-transparent: linear-gradient(0deg, var(--cinemo-colors-black-800) 0%, transparent 100%);
    --cinemo-gradient-dark-transparent: linear-gradient(0deg, var(--cinemo-colors-black-900) 0%, transparent 100%);
    --cinemo-gradient-player-background: radial-gradient(
        circle at 50% 10%,
        var(--cinemo-colors-accent-300) -150%,
        var(--cinemo-colors-black-900) 60%,
        transparent 100%,
        transparent 100%
    );

    --cinemo-shadow-area: 0px 0px 20px 0px var(--cinemo-colors-black-transparent-30);
    --cinemo-shadow-top: 0px -10px 20px 0px var(--cinemo-colors-black-transparent-50);
    --cinemo-shadow-bottom: 0px 6px 10px 0px var(--cinemo-colors-black-transparent-20);

    --cinemo-blur: blur(10px);

    --cinemo-sizes-icon-xxs: 1rem;
    --cinemo-sizes-icon-xs: 1.6rem;
    --cinemo-sizes-icon-s: 2.2rem;
    --cinemo-sizes-icon-m: 2.4rem;
    --cinemo-sizes-icon-l: 3.2rem;
    --cinemo-sizes-icon-xl: 6.4rem;
    --cinemo-sizes-icon-xxl: 9.6rem;

    --cinemo-sizes-cover-xs: 4.8rem;
    --cinemo-sizes-cover-s: 6rem;
    --cinemo-sizes-cover-m: 16.4rem;
    --cinemo-sizes-cover-l: 18rem;
    --cinemo-sizes-cover-xl: 30rem;
    --cinemo-sizes-cover-xxl: 40rem;

    // NOTE: radius-xxs removed from v2 design
    --cinemo-sizes-radius-xxs: 2px;
    --cinemo-sizes-radius-xs: 2px;
    --cinemo-sizes-radius-s: 4px;
    --cinemo-sizes-radius-m: 6px;
    --cinemo-sizes-radius-l: 8px;
    --cinemo-sizes-radius-xl: 12px;
    --cinemo-sizes-radius-xxl: 20px;
    --cinemo-sizes-radius-full: 50%;

    --cinemo-sizes-border-s: 0.1rem;

    // NOTE: space-xxs removed from v2 design
    --cinemo-sizes-space-xxxs: 0.2rem;
    --cinemo-sizes-space-xxs: 0.2rem;
    --cinemo-sizes-space-xs: 0.4rem;
    --cinemo-sizes-space-s: 0.6rem;
    --cinemo-sizes-space-m: 0.8rem;
    --cinemo-sizes-space-l: 1rem;
    --cinemo-sizes-space-xl: 1.2rem;
    --cinemo-sizes-space-xxl: 1.6rem;
    --cinemo-sizes-space-xxxl: 2.4rem;
    --cinemo-sizes-space-4xl: 3.2rem;

    // NOTE: gap-xxxl removed from v2 design
    --cinemo-sizes-gap-xxs: 0.4rem;
    --cinemo-sizes-gap-xs: 0.8rem;
    --cinemo-sizes-gap-s: 1.6rem;
    --cinemo-sizes-gap-m: 2.4rem;
    --cinemo-sizes-gap-l: 3.2rem;
    --cinemo-sizes-gap-xl: 4.8rem;
    --cinemo-sizes-gap-xxl: 5.6rem;
    --cinemo-sizes-gap-xxxl: 5.6rem;

    --cinemo-sizes-keyline-xs: 0.4rem;
    --cinemo-sizes-keyline-s: 0.8rem;
    --cinemo-sizes-keyline-m: 1.6rem;
    --cinemo-sizes-keyline-l: 2.4rem;
    --cinemo-sizes-keyline-xl: 3.2rem;

    --cinemo-sizes-container-width-m: 55.2rem;

    @include devices.tablet-portrait {
        --cinemo-sizes-gap-xxs: 0.4rem;
        --cinemo-sizes-gap-xs: 0.8rem;
        --cinemo-sizes-gap-s: 2.4rem;
        --cinemo-sizes-gap-m: 3.2rem;
        --cinemo-sizes-gap-l: 4.8rem;
        --cinemo-sizes-gap-xl: 6.4rem;
        --cinemo-sizes-gap-xxl: 9.6rem;
        --cinemo-sizes-gap-xxxl: 9.6rem;

        --cinemo-sizes-cover-xl: 52rem;

        --cinemo-sizes-keyline-xs: 0.8rem;
        --cinemo-sizes-keyline-s: 1.6rem;
        --cinemo-sizes-keyline-m: 2.4rem;
        --cinemo-sizes-keyline-l: 3.2rem;
        --cinemo-sizes-keyline-xl: 4.8rem;
    }

    @include devices.tablet-landscape {
        --cinemo-sizes-gap-xxs: 0.4rem;
        --cinemo-sizes-gap-xs: 0.8rem;
        --cinemo-sizes-gap-s: 1.6rem;
        --cinemo-sizes-gap-m: 2.4rem;
        --cinemo-sizes-gap-l: 3.2rem;
        --cinemo-sizes-gap-xl: 4.8rem;
        --cinemo-sizes-gap-xxl: 6.4rem;
        --cinemo-sizes-gap-xxxl: 6.4rem;

        --cinemo-sizes-cover-xl: 40rem;

        --cinemo-sizes-keyline-xs: 1.6rem;
        --cinemo-sizes-keyline-s: 2.4rem;
        --cinemo-sizes-keyline-m: 3.2rem;
        --cinemo-sizes-keyline-l: 4.8rem;
        --cinemo-sizes-keyline-xl: 6.4rem;
    }

    @include devices.desktop {
        --cinemo-sizes-gap-xxs: 0.4rem;
        --cinemo-sizes-gap-xs: 0.8rem;
        --cinemo-sizes-gap-s: 1.6rem;
        --cinemo-sizes-gap-m: 2.4rem;
        --cinemo-sizes-gap-l: 3.2rem;
        --cinemo-sizes-gap-xl: 4.8rem;
        --cinemo-sizes-gap-xxl: 6.4rem;
        --cinemo-sizes-gap-xxxl: 6.4rem;

        --cinemo-sizes-cover-xl: 52rem;

        --cinemo-sizes-keyline-xs: 1.6rem;
        --cinemo-sizes-keyline-s: 2.4rem;
        --cinemo-sizes-keyline-m: 3.2rem;
        --cinemo-sizes-keyline-l: 4.8rem;
        --cinemo-sizes-keyline-xl: 6.4rem;
    }
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('@assets/fonts/Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

body {
    color: var(--cinemo-colors-black-100);

    @include typography.body-primary;

    svg {
        width: var(--cinemo-sizes-icon-m);
        height: var(--cinemo-sizes-icon-m);
    }
}

h1 {
    @include typography.h1;
}

h2 {
    @include typography.h2;
}

h3 {
    @include typography.h3;
}

h4 {
    @include typography.h4;
}

h5 {
    @include typography.h5;
}

h6 {
    @include typography.h6;
}
