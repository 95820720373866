@use 'typography';

.bif-components-slider {
    $name: &;

    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'label label label'
        'slot-left input slot-right';
    align-items: center;

    svg {
        width: var(--cinemo-sizes-icon-s);
        height: var(--cinemo-sizes-icon-s);
    }

    &--spacing-regular {
        margin-bottom: var(--cinemo-sizes-space-s);
    }

    &--layout-fixed {
        width: max-content;

        & #{$name}__input__track {
            &[data-orientation='horizontal'] {
                width: 31rem;
            }
        }
    }

    &__label {
        grid-area: label;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: max-content;
        color: var(--cinemo-colors-black-300);

        @include typography.body-primary;
    }

    &__slot {
        display: grid;
        justify-items: center;
        align-items: center;
        max-width: fit-content;
        color: var(--cinemo-colors-black-100);

        &--side-left {
            grid-area: slot-left;
            margin-right: var(--cinemo-sizes-space-m);
        }

        &--side-right {
            grid-area: slot-right;
            margin-left: var(--cinemo-sizes-space-m);
        }

        &--inactive {
            color: var(--cinemo-colors-white-transparent-30);
        }
    }

    &__input {
        grid-area: input;
        display: grid;
        justify-items: center;
        align-items: center;
        position: relative;
        min-height: 2.2rem;

        &--has-thumb {
            & #{$name}__input__track {
                background-color: var(--cinemo-colors-black-transparent-50);
            }

            & #{$name}__input__track__range {
                background-color: var(--cinemo-colors-black-200);
            }
        }

        &--inactive {
            & #{$name}__input__track {
                background-color: var(--cinemo-colors-black-transparent-20);
            }

            & #{$name}__input__track__range {
                background-color: var(--cinemo-colors-white-transparent-10);
            }
        }

        &--disabled {
            & #{$name}__input__track,
            & #{$name}__input__track__range,
            & #{$name}__input__thumb,
            & #{$name}__input__thumb:hover {
                cursor: not-allowed;
            }

            & #{$name}__input__track {
                background-color: var(--cinemo-colors-black-transparent-20);
            }

            & #{$name}__input__track__range {
                background-color: var(--cinemo-colors-white-transparent-10);
            }
        }

        @media (hover: hover) and (pointer: fine) {
            &:not(&--has-thumb):not(&--disabled):hover {
                & #{$name}__input__track[data-orientation='horizontal'] {
                    height: 1.6rem;

                    & #{$name}__input__track__range {
                        height: 1.6rem;
                    }
                }
            }
        }

        &--touch-active {
            & #{$name}__input__track[data-orientation='horizontal'] {
                height: 1.6rem;

                & #{$name}__input__track__range {
                    height: 1.6rem;
                }
            }
        }

        &__track {
            display: block;
            position: relative;
            border-radius: var(--cinemo-sizes-radius-xs);
            background-color: var(--cinemo-colors-white-transparent-05);
            transition: height 0.3s ease;

            &[data-orientation='horizontal'] {
                width: 100%;
                height: 0.4rem;
            }

            &[data-orientation='vertical'] {
                width: 0.4rem;
                height: 20rem;
            }

            &__range {
                position: absolute;
                border-radius: var(--cinemo-sizes-radius-xs);
                background-color: var(--cinemo-colors-black-100);
                transition: height 0.3s ease;

                &[data-orientation='horizontal'] {
                    height: 0.4rem;
                }

                &[data-orientation='vertical'] {
                    width: 0.4rem;
                }
            }
        }

        &__thumb {
            display: block;
            width: var(--cinemo-sizes-icon-xs);
            height: var(--cinemo-sizes-icon-xs);
            border-radius: var(--cinemo-sizes-radius-full);
            background-color: var(--cinemo-colors-black-100);
            box-shadow: var(--cinemo-shadow-area);

            &:hover,
            &:focus {
                background-color: var(--cinemo-colors-black-300);
            }

            &:focus {
                outline: none;
            }

            &--visually-hidden {
                opacity: 0;
            }
        }
    }
}
