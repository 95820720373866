@use 'typography';
@use 'devices';

.bif-components-confirm-dialog {
    .bif-components-dialog__content__title {
        margin-bottom: 0;
    }

    &__description {
        margin-top: var(--cinemo-sizes-gap-m);

        @include typography.body-primary;
        color: var(--cinemo-colors-white-transparent-70);
        text-align: center;
        overflow: auto;
    }

    &__actions + .bif-components-dialog__content__close {
        display: none;
    }
}
