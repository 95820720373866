@use 'typography';

.bif-components-img-cropper {
    &__inner-container {
        display: grid;
        grid-gap: var(--cinemo-sizes-gap-m);
        align-content: start;
        grid-auto-flow: row;

        * {
            user-select: none;
        }

        &__note {
            @include typography.body-primary;
            color: var(--cinemo-colors-white-transparent-70);
        }

        &__cropper {
            position: relative;
            height: 36rem;
            max-width: 48.8rem;

            &__crop-area {
                border: var(--cinemo-sizes-border-s) solid var(--cinemo-colors-black-400);
                border-radius: var(--cinemo-sizes-radius-l);
                /* notes about cropper crop area (react-easy-crop@5.2.0),
                    - setting width/height with !important causes an offset
                    - setting them with cropSize prop also introduces problems (with portrait images)
                    - the dimensions are determined by the container dimensions, aspect prop and selected image dimensions
                */
            }
        }

        &__controller {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: var(--cinemo-sizes-gap-s);
            align-items: end;

            &__rotation {
                display: grid;
                grid-gap: var(--cinemo-sizes-gap-xs);
                align-content: start;
                grid-auto-flow: row;
                grid-template-columns: auto auto;

                svg {
                    width: var(--cinemo-sizes-icon-l);
                    height: var(--cinemo-sizes-icon-l);
                    fill: var(--cinemo-colors-black-100);
                    cursor: pointer;
                }
            }
        }
    }

    .bif-components-dialog__content__close {
        display: none;
    }

    .bif-components-dialog__content__title {
        user-select: none;
    }
}
