@use 'typography';

.bif-components-tooltip {
    /* NOTE: manipulation of data-radix-popper-content wrapper is a workaround solution as mentioned here: 
    /* https://github.com/radix-ui/primitives/issues/1839#issuecomment-1366326545
    /* https://github.com/radix-ui/primitives/issues/1181#issuecomment-1049185802
    */
    & [data-radix-popper-content-wrapper] {
        z-index: 5 !important;
        min-width: auto !important;
    }

    &__trigger {
        cursor: pointer;
    }

    &__content {
        display: grid;
        border-radius: var(--cinemo-sizes-radius-l);
        background-color: var(--cinemo-colors-black-100);
        color: var(--cinemo-colors-black-900);
        box-shadow: var(--cinemo-shadow-area);
        max-width: 24rem;

        @include typography.body-secondary;

        &--size-small {
            padding: 0.4rem 0.8rem;

            .bif-components-tooltip__content__arrow {
                display: none !important;
            }
        }

        &--size-medium {
            padding: var(--cinemo-sizes-space-xl) var(--cinemo-sizes-space-xxl);
        }

        &__arrow {
            width: 2rem !important;
            height: 1.2rem !important;
            fill: var(--cinemo-colors-black-100);
        }
    }
}
