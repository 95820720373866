@use 'devices';
@use 'typography';
@use 'events';

/* NOTE: manipulation of data-radix-popper-content wrapper is a workaround solution as mentioned here: 
/* https://github.com/radix-ui/primitives/issues/1839#issuecomment-1366326545
/* https://github.com/radix-ui/primitives/issues/1181#issuecomment-1049185802
*/
.bif-components-menu__portal--mobile-fullscreen > [data-radix-popper-content-wrapper] {
    @include devices.phone {
        position: fixed !important;
        left: 0rem !important;
        top: 0rem !important;
        transform: translate(0rem, 0rem) !important;
    }
}

.bif-components-menu {
    $base: &;

    &__trigger {
        &--portal {
            position: fixed;
            margin: 0;
            z-index: 3;
            visibility: hidden;
            pointer-events: none;

            &--visible {
                visibility: visible;
                pointer-events: auto;
            }
        }

        &__icon {
            &,
            &:hover,
            &:focus {
                border-color: transparent;
            }

            &[data-state='open'] {
                @include events.hover-focus {
                    &:before {
                        background-color: var(--cinemo-colors-black-800);
                    }
                }

                &:before {
                    background-color: var(--cinemo-colors-black-800);
                }
            }
        }
    }

    &__overlay {
        position: fixed;
        inset: 0rem;
        height: 100vh;
        width: 100vw;
        background-color: var(--cinemo-colors-black-transparent-50);
        backdrop-filter: var(--cinemo-blur);
        z-index: 2;
    }

    &__portal {
        &--small-version {
            @include devices.tablet-portrait-up {
                #{$base}__content__options__item > button {
                    padding: var(--cinemo-sizes-space-xl) var(--cinemo-sizes-space-xxxl);
                }
            }
        }
    }

    &__content {
        display: grid;
        grid-template-columns: max-content;
        grid-template-rows: max-content max-content 1fr max-content;
        grid-template-areas: 'inner' 'title' 'options' 'close';
        padding: var(--cinemo-sizes-space-m) 0;
        border-radius: var(--cinemo-sizes-radius-xl);
        background-color: var(--cinemo-colors-black-800);
        box-shadow: var(--cinemo-shadow-area);
        z-index: 2;

        &__inner {
            display: none;
        }

        &__title {
            display: none;
        }

        &__options {
            grid-area: options;
            display: grid;
            grid-auto-flow: row;
            grid-auto-rows: max-content;
            overflow-y: auto;
            z-index: 1;

            &__item {
                outline: none;

                @include devices.tablet-portrait-up {
                    @include events.hover-focus {
                        color: var(--cinemo-colors-black-100);
                        background-color: var(--cinemo-colors-white-transparent-10);
                    }
                }

                & > button {
                    font-weight: 400;
                    width: 100%;
                    text-align: left;
                    border-radius: 0;
                    padding: var(--cinemo-sizes-space-xxl) var(--cinemo-sizes-space-4xl);

                    @include events.hover-focus {
                        color: var(--cinemo-colors-black-100);
                    }
                }
            }
        }

        &__close {
            display: none;
        }

        &--mobile-fullscreen {
            @include devices.phone {
                grid-template-columns: auto;
                width: 100vw;
                height: 100vh;
                border-radius: 0;
                margin: 0;
                padding: 0;
                padding-top: calc(6.4rem + env(safe-area-inset-top));
                padding-bottom: env(safe-area-inset-bottom);
                box-sizing: border-box;

                &::before {
                    content: '';
                    position: absolute;
                    top: 3.8rem;
                    left: -10.2rem;
                    width: 30rem;
                    height: 30rem;
                    background: var(--cinemo-colors-accent-300);
                    opacity: 0.2;
                    border-radius: 50%;
                    filter: blur(7.5rem);
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 20rem;
                    right: -20rem;
                    width: 41.6rem;
                    height: 41.6rem;
                    background: var(--cinemo-colors-accent-800);
                    opacity: 0.2;
                    border-radius: 50%;
                    filter: blur(7.5rem);
                }

                #{$base}__content__inner {
                    grid-area: inner;
                    display: grid;
                    grid-auto-flow: row;
                    justify-items: center;
                    align-items: center;
                    margin-top: calc(-1 * var(--cinemo-sizes-space-4xl));
                    margin-bottom: var(--cinemo-sizes-gap-s);
                    padding: 0 var(--cinemo-sizes-keyline-xl);
                    z-index: 1;

                    img {
                        max-width: var(--cinemo-sizes-cover-xl);
                        max-height: var(--cinemo-sizes-cover-m);
                    }
                }

                #{$base}__content__title {
                    grid-area: title;
                    display: grid;
                    grid-auto-flow: row;
                    gap: var(--cinemo-sizes-gap-xxs);
                    justify-items: center;
                    align-items: center;
                    margin-bottom: var(--cinemo-sizes-gap-l);
                    padding: 0 var(--cinemo-sizes-keyline-xl);
                    color: var(--cinemo-colors-black-100);
                    z-index: 1;

                    @include typography.h2;
                }

                #{$base}__content__caption {
                    color: var(--cinemo-colors-white-transparent-50);

                    @include typography.caption-primary;
                }

                #{$base}__content__options {
                    padding: 0;
                    margin-top: auto;

                    &__item {
                        & > button {
                            padding: var(--cinemo-sizes-space-xxl)
                                calc(var(--cinemo-sizes-keyline-xl) + var(--cinemo-sizes-gap-xl));
                        }
                    }
                }

                #{$base}__content__close {
                    grid-area: close;
                    height: 7.8rem;
                    width: 100%;
                    display: grid;
                    place-items: center;
                    justify-self: center;
                    margin: 3.2rem 0 1.6rem;
                    padding: 0 var(--cinemo-sizes-space-xl);

                    @include events.hover-focus {
                        color: var(--cinemo-colors-black-100);
                    }
                }
            }
        }
    }
}
