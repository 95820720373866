@use 'typography';

.bif-components-text-overflow {
    width: 100%;
    margin: 0;
    display: inline-block;
    position: relative;
    text-align: center;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.bif-components-text-tooltip {
    display: inline-flex;
    width: 100%;
    overflow-x: hidden;
    white-space: normal;

    &__content {
        max-width: 80vw !important;
        word-break: break-word;
    }
}

.bif-components-text-inline {
    display: inline-flex;
    white-space: nowrap;
    max-width: 100%;
}

.bif-components-text-wrap {
    word-break: break-word;
    font-weight: 700;
    color: var(--cinemo-colors-black-100);
}
