@use 'typography';

.bif-components-separator {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    align-items: center;
    column-gap: var(--cinemo-sizes-space-m);
    user-select: none;

    &--label-position-left {
        grid-template-columns: auto 1fr;

        .bif-components-separator__line:nth-child(1) {
            display: none;
        }
    }

    &--label-position-center {
        grid-template-columns: 1fr auto 1fr;
    }

    &--label-position-right {
        grid-template-columns: 1fr auto;

        .bif-components-separator__line:nth-child(2) {
            display: none;
        }
    }

    &--size-inline {
        margin: 0rem;
    }

    &--size-small {
        margin: var(--cinemo-sizes-space-xs) 0rem;
    }

    &--size-medium {
        margin: var(--cinemo-sizes-space-l) 0rem;
    }

    &--size-large {
        margin: var(--cinemo-sizes-space-xl) 0rem;
    }

    &__label {
        text-transform: uppercase;
        color: var(--cinemo-colors-white-transparent-60);

        @include typography.caption-primary;
    }

    &__line {
        width: 100%;
        border-color: var(--cinemo-colors-white-transparent-20);
    }
}
