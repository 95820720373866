@use 'typography';
@use 'events';

.bif-components-combobox {
    $name: &;

    display: grid;
    grid-auto-flow: row;
    grid-template-areas:
        'label'
        'trigger'
        'validation';

    grid-gap: var(--cinemo-sizes-space-s);

    svg {
        width: var(--cinemo-sizes-icon-s);
        height: var(--cinemo-sizes-icon-s);
    }

    &--spacing-regular {
        & > *:last-child {
            margin-bottom: var(--cinemo-sizes-space-xxxl);
        }
    }

    &--layout-fixed {
        width: 34rem;
    }

    &--layout-max {
        width: 100%;
    }

    &--theme-dark {
        #{$name}__trigger {
            border-color: var(--cinemo-colors-black-900);
            background-color: var(--cinemo-colors-black-900);

            &:focus-within,
            &--open {
                background-color: var(--cinemo-colors-black-900);
                border-color: var(--cinemo-colors-black-700);

                & > #{$name}__trigger__input {
                    background-color: var(--cinemo-colors-black-900);
                    border-color: var(--cinemo-colors-black-700);
                }
            }

            &__input {
                background-color: var(--cinemo-colors-black-900);
            }

            &--empty {
                &,
                & > #{$name}__trigger__input {
                    background-color: var(--cinemo-colors-black-850);
                    border-color: var(--cinemo-colors-black-850);
                }

                &#{$name}__trigger--rounded {
                    #{$name}__trigger__input {
                        &::placeholder {
                            color: var(--cinemo-colors-white-transparent-50);
                        }
                        &:focus-visible {
                            outline: none;
                            &::placeholder {
                                color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    &--theme-light {
        #{$name}__trigger {
            border-color: var(--cinemo-colors-black-800);
            background-color: var(--cinemo-colors-black-800);

            &__input {
                background-color: var(--cinemo-colors-black-800);
            }

            &--empty {
                &,
                & > #{$name}__trigger__input {
                    background-color: var(--cinemo-colors-black-800);
                }
            }

            &:focus-within,
            &--open {
                &,
                & > #{$name}__trigger__input {
                    border-color: var(--cinemo-colors-black-700);
                    background-color: var(--cinemo-colors-black-800);
                }
            }
        }
    }

    &__label {
        grid-area: label;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: min-content;
        column-gap: var(--cinemo-sizes-space-xxxs);
        margin-bottom: var(--cinemo-sizes-space-xxs);
        color: var(--cinemo-colors-black-300);

        @include typography.body-secondary;

        &__text {
            width: max-content;
        }

        &__content {
            display: inline-grid;
            align-items: center;

            svg {
                width: var(--cinemo-sizes-icon-xs);
                height: var(--cinemo-sizes-icon-xs);
            }
        }
    }

    &__trigger {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto;
        grid-template-areas: 'focus action';
        align-items: center;
        padding: 0 var(--cinemo-sizes-space-xl);
        border: var(--cinemo-sizes-border-s) solid;
        border-radius: var(--cinemo-sizes-radius-m);
        color: var(--cinemo-colors-black-100);
        box-sizing: border-box;
        column-gap: var(--cinemo-sizes-space-s);

        &:not(&--disabled) {
            @include events.hover-focus {
                border-color: var(--cinemo-colors-black-700);
            }
        }

        &--disabled {
            color: var(--cinemo-colors-black-600);
            background-color: var(--cinemo-colors-black-850);

            &,
            #{$name}__trigger__action {
                cursor: not-allowed;
            }

            & > #{$name}__trigger__input {
                background-color: var(--cinemo-colors-black-850);
            }
        }

        &--error {
            border-color: var(--cinemo-colors-red-400) !important;
        }

        &__selected-items {
            grid-area: focus;
            padding: var(--cinemo-sizes-space-l) 0;
            display: flex;
            flex-wrap: nowrap;
            position: relative;
            overflow: hidden;

            .rc-overflow-item {
                padding-right: var(--cinemo-sizes-space-s);

                &-rest {
                    padding-right: 0;
                }
            }

            ~ #{$name}__trigger__input {
                position: absolute;
                width: 0;
                opacity: 0;
                margin: 0;
            }

            &__item {
                padding: var(--cinemo-sizes-space-xs) var(--cinemo-sizes-space-m) !important;

                &--counter {
                    pointer-events: none;
                }

                &__text {
                    @include typography.body-secondary;
                    text-wrap: nowrap;
                }

                &__button {
                    display: flex;
                    background: transparent;
                    border: none;
                    color: var(--cinemo-colors-black-100);
                    cursor: pointer;

                    svg {
                        width: var(--cinemo-sizes-icon-xs);
                        height: var(--cinemo-sizes-icon-xs);
                    }
                }
            }
        }

        &__input {
            grid-area: focus;
            width: 100%;
            padding: var(--cinemo-sizes-space-xl) 0;
            border: none;
            border-radius: var(--cinemo-sizes-radius-s);
            color: var(--cinemo-colors-black-100);

            @include typography.body-primary;

            &::placeholder {
                color: var(--cinemo-colors-white-transparent-60);
            }

            &::-moz-placeholder {
                opacity: 1;
            }

            &:focus-visible {
                outline: none;
                &::placeholder {
                    color: transparent;
                }
            }

            &:placeholder-shown {
                text-overflow: ellipsis;
            }

            &--disabled {
                cursor: not-allowed;
                color: var(--cinemo-colors-black-600);

                &::placeholder {
                    color: var(--cinemo-colors-black-600);
                }
            }

            &--non-searchable {
                cursor: pointer;
            }
        }

        &__action {
            grid-area: action;
            margin: var(--cinemo-sizes-space-xl) 0 var(--cinemo-sizes-space-xl) var(--cinemo-sizes-space-m);
            border: none;
            cursor: pointer;
            background-color: transparent;
            color: inherit;

            &,
            svg {
                width: var(--cinemo-sizes-icon-s);
                height: var(--cinemo-sizes-icon-s);
            }

            &--popover-open {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__popover__content {
        z-index: 4;

        // Without pointer-events the popover would be non-clickable inside the dialog
        // https://github.com/radix-ui/primitives/issues/2122
        pointer-events: auto;
    }

    &__popover__content[data-side='top'] {
        #{$name}__items {
            border-radius: var(--cinemo-sizes-radius-m) var(--cinemo-sizes-radius-m) 0 0;

            &--theme-dark {
                border-bottom-color: var(--cinemo-colors-black-900);
            }

            &--theme-light {
                border-bottom-color: var(--cinemo-colors-black-800);
            }
        }
    }

    &__popover__content[data-side='bottom'] {
        #{$name}__items {
            border-radius: 0 0 var(--cinemo-sizes-radius-m) var(--cinemo-sizes-radius-m);

            &--theme-dark {
                border-top-color: var(--cinemo-colors-black-900);
            }

            &--theme-light {
                border-top-color: var(--cinemo-colors-black-800);
            }
        }
    }

    &__items {
        $items: &;
        list-style: none;
        padding: 0;
        margin: 0;
        width: var(--radix-popover-trigger-width);
        border: 1px solid var(--cinemo-colors-black-700);
        border-radius: var(--cinemo-sizes-radius-m);
        box-sizing: border-box;

        &--theme-dark {
            background-color: var(--cinemo-colors-black-900);
        }

        &--theme-light {
            background-color: var(--cinemo-colors-black-800);
        }

        &--empty {
            border: none;
        }

        .bif-components-scrollable-area {
            &__viewport {
                max-height: min(var(--radix-popover-content-available-height), 48rem);
            }

            &__scrollbar {
                right: 0.4rem !important;
            }
        }

        &__item {
            $item: &;
            position: relative;
            overflow: hidden;
            border-radius: var(--cinemo-sizes-radius-m);
            cursor: pointer;

            &--selected {
                #{$item}__checkbox {
                    border-color: var(--cinemo-colors-black-100);
                    background: url('@assets/svg/Icon.Check.svg') var(--cinemo-colors-black-100);
                    background-size: cover;
                }
            }

            &[aria-selected='true'],
            &--selected {
                #{$items}__item__label {
                    background-color: var(--cinemo-colors-white-transparent-10);
                }
            }

            &--prev-selected {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            &--next-selected {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            &--disabled {
                cursor: not-allowed;

                #{$item}__checkbox {
                    border-color: var(--cinemo-colors-white-transparent-10);
                }

                #{$item}__label {
                    color: var(--cinemo-colors-white-transparent-30);
                }
            }

            &--without-checkbox {
                #{$item}__checkbox {
                    display: none;
                }

                #{$item}__label {
                    padding-left: var(--cinemo-sizes-space-xl);
                }
            }

            &__checkbox {
                position: absolute;
                left: 1.2rem;
                top: 1.2rem;
                border: 1.5px solid var(--cinemo-colors-black-600);
                width: var(--cinemo-sizes-icon-m);
                height: var(--cinemo-sizes-icon-m);
                accent-color: var(--cinemo-colors-black-100);
                border-radius: 50%;
            }

            &__label {
                width: 100%;
                min-height: 4.8rem;
                padding: 0 var(--cinemo-sizes-space-l) 0 4.4rem;
                display: grid;
                grid-template-columns: 1fr max-content;
                grid-template-rows: 4.8rem 1fr;
                align-items: center;
                background-color: transparent;
                @include typography.body-primary;
                color: var(--cinemo-colors-black-100);
                text-align: left;
                user-select: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow-x: hidden;
            }
        }
    }

    &__validation {
        grid-area: validation;
    }
}
