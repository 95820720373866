@use 'typography';

.bif-components-upload {
    display: grid;
    grid-template-columns: 6.4rem max-content;
    gap: var(--cinemo-sizes-gap-s);
    justify-content: center;
    padding: 5rem 0;
    /* dashed border */
    background-image: url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' fill='none' rx='20' ry='20' stroke='%23494a56' stroke-width='1' stroke-dasharray='6, 14' stroke-dashoffset='30' stroke-linecap='square'/></svg>");
    cursor: pointer;

    &__input {
        display: none;
    }

    &__icon {
        display: grid;
        align-self: center;
        place-items: center;
        grid-row: span 2;
        width: var(--cinemo-sizes-icon-xl);
        height: var(--cinemo-sizes-icon-xl);
        border-radius: var(--cinemo-sizes-radius-full);
        background-color: var(--cinemo-colors-white-transparent-10);
    }

    &__title {
        margin: 0;
        @include typography.h5;
        color: var(--cinemo-colors-black-100);
    }

    &__rules {
        @include typography.caption-primary;
        color: var(--cinemo-colors-white-transparent-70);
    }
}

.bif-components-upload-preview {
    display: grid;
    grid-template-areas:
        'image actions'
        'image actions'
        'image name';
    grid-template-columns: 14.8rem auto;

    border-radius: var(--cinemo-sizes-radius-m);
    gap: var(--cinemo-sizes-space-m);
    padding: var(--cinemo-sizes-space-m);
    background: var(--cinemo-colors-white-transparent-10);

    &__image {
        width: 14.8rem;
        height: 14.8rem;
        grid-area: image;
        border-radius: var(--cinemo-sizes-radius-m);
    }

    &__actions {
        grid-area: actions;
        display: flex;
        align-items: start;
        justify-content: end;

        button {
            margin: var(--cinemo-sizes-space-xl);
            padding: 0;
        }
    }

    &__name {
        grid-area: name;
        align-self: end;
        padding: var(--cinemo-sizes-space-xxl) var(--cinemo-sizes-space-m);
        background: var(--cinemo-colors-white-transparent-20);
        border-radius: var(--cinemo-sizes-radius-s);
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.bif-components-upload__files {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--cinemo-sizes-gap-xxs);

    &__file {
        @include typography.h5;

        display: grid;
        grid-template-columns: 1fr auto;
        border-radius: var(--cinemo-sizes-radius-m);
        padding: var(--cinemo-sizes-space-xl) var(--cinemo-sizes-space-xxl);
        background-color: var(--cinemo-colors-white-transparent-10);
        color: #fafafa;

        &__remove {
            color: var(--cinemo-colors-black-100);
        }
    }
}
