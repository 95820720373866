@use 'typography';
@use 'events';

.bif-components-pagination {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: var(--cinemo-sizes-space-xxl);
    justify-content: right;

    &__navigation {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: var(--cinemo-sizes-space-xs);

        &__item {
            @include typography.caption-primary;

            border-radius: var(--cinemo-sizes-radius-s);
            background-color: var(--cinemo-colors-white-transparent-10);
            color: var(--cinemo-colors-black-100);
            height: 2.4rem;
            width: 2.2rem;
            padding: 0;
            user-select: none;

            &--prev,
            &--next {
                width: auto;
                padding: 0 var(--cinemo-sizes-space-m);

                &__slot__icon {
                    width: var(--cinemo-sizes-icon-xs) !important;
                }
            }

            &--prev__slot {
                margin-right: var(--cinemo-sizes-space-xs) !important;
            }

            &--next__slot {
                margin-left: var(--cinemo-sizes-space-xs) !important;
            }

            &--active {
                background-color: var(--cinemo-colors-black-100);
                color: var(--cinemo-colors-black-900);
            }

            &:not(:disabled) {
                @include events.hover {
                    background-color: var(--cinemo-colors-white-transparent-20);
                    color: var(--cinemo-colors-black-100);
                }
            }
        }
    }

    &__page-size {
        display: grid;
        align-items: center;

        .bif-components-combobox {
            grid-template-columns: auto auto;
            grid-template-areas: 'label trigger';
            gap: var(--cinemo-sizes-space-xs);
            width: 17rem;
            -webkit-justify-content: space-between;
        }

        &__label {
            @include typography.caption-primary;

            color: var(--cinemo-colors-black-100);
            align-items: center;
        }

        &__item {
            .bif-components-combobox__items__item__label {
                @include typography.caption-primary;

                display: flex;
                min-height: 2.6rem;
            }
        }

        &__input {
            max-width: 7rem;
            background-color: var(--cinemo-colors-white-transparent-10) !important;

            .bif-components-combobox__trigger__input {
                @include typography.caption-primary;

                padding: 0;
                background-color: transparent !important;
            }

            .bif-components-combobox__trigger__action {
                margin: var(--cinemo-sizes-space-xs) 0 0 0;
                padding: 0;

                svg {
                    width: var(--cinemo-sizes-icon-xs);
                    height: var(--cinemo-sizes-icon-xs);
                }
            }
        }
    }
}
