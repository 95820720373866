@use 'typography';
@use 'events';

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(3deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-3deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.bif-components-button {
    $name: &;

    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    grid-template-areas: 'slot-left text slot-right';
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4rem;
    transition-property: background, box-shadow, color;
    transition-duration: 0.5s;
    background-size: 200%;

    @include typography.button;

    @include events.hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    &--animated {
        animation: tilt-shaking 0.12s 3;
    }

    &--layout-min {
        width: max-content;
    }

    &--layout-fixed {
        min-width: 16.4rem;
    }

    &--layout-max {
        width: 100%;
    }

    &--type-primary {
        background-color: var(--cinemo-colors-accent-300);
        background-image: var(--cinemo-gradient-brand-accent);
        color: var(--cinemo-colors-black-900);

        @include events.hover-focus {
            background-color: var(--cinemo-colors-accent-200);
            background-position: right;
        }

        &#{$name}--danger {
            background: var(--cinemo-colors-red-500);
            color: var(--cinemo-colors-black-100);

            @include events.hover-focus {
                background: var(--cinemo-colors-red-700);
            }
        }

        &#{$name}--loading,
        &#{$name}--disabled {
            background: var(--cinemo-colors-black-700);
            color: var(--cinemo-colors-black-400);

            .bif-components-badge {
                color: var(--cinemo-colors-black-400);
            }

            @include events.hover {
                background: var(--cinemo-colors-black-700);
                color: var(--cinemo-colors-black-400);

                .bif-components-badge {
                    color: var(--cinemo-colors-black-400);
                }
            }
        }
    }

    &--type-secondary {
        --border-width: 1.5px;
        box-shadow: inset 0 0 0 var(--border-width) var(--cinemo-colors-black-100);
        background: transparent;
        color: var(--cinemo-colors-black-100);

        @include events.hover-focus {
            box-shadow: inset 0 0 0 var(--border-width) var(--cinemo-colors-black-300);
            color: var(--cinemo-colors-black-300);
        }

        &#{$name}--danger {
            box-shadow: inset 0 0 0 var(--border-width) var(--cinemo-colors-red-500);
            color: var(--cinemo-colors-red-500);

            .bif-components-badge {
                color: var(--cinemo-colors-red-500);
            }

            @include events.hover-focus {
                box-shadow: inset 0 0 0 var(--border-width) var(--cinemo-colors-red-700);
                color: var(--cinemo-colors-red-700);

                .bif-components-badge {
                    color: var(--cinemo-colors-red-700);
                }
            }
        }

        &#{$name}--loading,
        &#{$name}--disabled {
            box-shadow: inset 0 0 0 var(--border-width) var(--cinemo-colors-black-500);
            color: var(--cinemo-colors-black-500);

            .bif-components-badge {
                color: var(--cinemo-colors-black-500);
            }

            @include events.hover {
                box-shadow: inset 0 0 0 var(--border-width) var(--cinemo-colors-black-500);
                color: var(--cinemo-colors-black-500);

                .bif-components-badge {
                    color: var(--cinemo-colors-black-500);
                }
            }
        }
    }

    &--type-tertiary,
    &--type-tertiary--accent {
        background: transparent;

        &#{$name}--danger {
            color: var(--cinemo-colors-red-500);

            .bif-components-badge {
                color: var(--cinemo-colors-red-500);
            }

            @include events.hover-focus {
                color: var(--cinemo-colors-red-700);

                .bif-components-badge {
                    color: var(--cinemo-colors-red-700);
                }
            }
        }

        &#{$name}--loading,
        &#{$name}--disabled {
            color: var(--cinemo-colors-black-500);

            .bif-components-badge {
                color: var(--cinemo-colors-black-500);
            }

            @include events.hover-focus {
                color: var(--cinemo-colors-black-500);

                .bif-components-badge {
                    color: var(--cinemo-colors-black-500);
                }
            }
        }
    }

    &--type-tertiary {
        color: var(--cinemo-colors-black-100);

        @include events.hover-focus {
            color: var(--cinemo-colors-black-300);
        }
    }

    &--type-tertiary--accent {
        color: var(--cinemo-colors-accent-300);

        @include events.hover-focus {
            color: var(--cinemo-colors-accent-200);
        }
    }

    &--size-inline {
        padding: 0rem;
        box-shadow: none !important;

        #{$name}__slot {
            &--left {
                margin-right: var(--cinemo-sizes-space-xxl);
            }

            &--right {
                margin-left: var(--cinemo-sizes-space-s);
            }

            &:only-child {
                margin: 0rem;
            }
        }
    }

    &--size-small {
        height: 3.8rem;
        padding: 0 var(--cinemo-sizes-space-xxl);

        #{$name}__slot {
            &--left {
                margin-right: var(--cinemo-sizes-space-m);
            }

            &--right {
                margin-left: var(--cinemo-sizes-space-m);
            }

            &:only-child {
                margin: 0 -0.8rem;
            }
        }
    }

    &--size-medium {
        height: 4.8rem;
        padding: 0 var(--cinemo-sizes-space-xxl);

        #{$name}__slot {
            &--left {
                margin-right: var(--cinemo-sizes-space-l);
            }

            &--right {
                margin-left: var(--cinemo-sizes-space-l);
            }

            &:only-child {
                margin: 0 -0.4rem;
            }
        }
    }

    &--loading {
        .bif-components-button__text,
        .bif-components-button__slot {
            opacity: 0;
        }

        .bif-components-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--loading,
    &--disabled {
        @include events.hover {
            cursor: not-allowed;
        }
    }

    &--centered {
        grid-template-columns: 2.2rem 1fr 2.2rem;
        gap: var(--cinemo-sizes-space-l);

        .bif-components-button__slot {
            margin: 0;
        }
    }

    &__slot {
        display: grid;
        justify-items: center;
        align-items: center;
        max-width: fit-content;

        svg {
            width: var(--cinemo-sizes-icon-s);
            height: var(--cinemo-sizes-icon-s);
        }

        .bif-components-badge {
            margin: -0.5rem;
        }

        &--left {
            grid-area: slot-left;
        }

        &--right {
            grid-area: slot-right;
        }
    }

    &__text {
        grid-area: text;
    }
}
