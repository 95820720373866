@use 'devices';
@use 'typography';

@mixin modal {
    padding: 3.2rem 2.4rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 6.4rem);
    max-width: 55.2rem;

    @include devices.tablet-landscape-up {
        padding: 2.4rem 3.2rem;
    }

    &[data-state='open'] {
        animation: scale-down 0.2s ease-out;
    }

    &[data-state='closed'] {
        animation: scale-up 0.1s ease-out;
    }
}

@mixin drawer {
    padding: var(--cinemo-sizes-gap-m);
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: calc(var(--cinemo-sizes-gap-m) + env(safe-area-inset-bottom));
}

@keyframes scale-down {
    from {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 0;
    }

    to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@keyframes scale-up {
    from {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    to {
        transform: translate(-50%, -50%) scale(1.1);
        opacity: 0;
    }
}

.bif-components-dialog {
    $name: &;

    &__overlay {
        position: fixed;
        z-index: 3;
        inset: 0rem;
        background-color: var(--cinemo-colors-black-transparent-50);
        backdrop-filter: var(--cinemo-blur);
    }

    &__content {
        display: grid;
        outline: none;
        grid-template-rows: max-content 1fr max-content;
        grid-template-areas:
            'title'
            'inner'
            'actions';
        max-height: calc(95vh - env(safe-area-inset-top, 0));
        overflow-y: auto;
        box-sizing: border-box;
        border-radius: var(--cinemo-sizes-radius-xl);
        background-color: var(--cinemo-colors-black-800);
        color: var(--cinemo-colors-black-100);
        box-shadow: var(--cinemo-shadow-bottom);
        z-index: 3;

        &--type-flexible {
            @include devices.phone {
                &#{$name}__content--layout-max {
                    max-height: 100%;
                    top: calc(var(--cinemo-sizes-gap-xxl) + env(safe-area-inset-top));
                }

                & #{$name}__content__close {
                    display: none;
                }

                @include drawer;
            }

            @include devices.tablet-portrait-up {
                @include modal;
            }
        }

        &--type-drawer {
            &#{$name}__content--layout-max {
                height: 100%;
                max-height: 100%;
                top: var(--cinemo-sizes-gap-l);
            }

            & #{$name}__content__close {
                display: none;
            }

            & #{$name}__content__inner {
                .bif-components-scrollable-area__viewport {
                    background-color: var(--cinemo-colors-black-700);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            @include drawer;
        }

        &--type-modal {
            @include modal;

            & #{$name}__content__actions {
                margin-top: var(--cinemo-sizes-gap-l);

                @include devices.tablet-portrait-up {
                    margin-top: var(--cinemo-sizes-gap-m);
                }
            }
        }

        &--layout-min,
        &--layout-max {
            max-width: 55.2rem;
        }

        &__title {
            grid-area: title;
            display: grid;
            justify-items: center;
            align-items: center;
            margin: 0rem;
            margin-bottom: var(--cinemo-sizes-gap-m);

            @include typography.h2;
            text-align: center;
        }

        &__inner {
            grid-area: inner;
            display: grid;
            width: 100%;

            @include typography.body-primary;
            text-align: center;
        }

        &__actions {
            grid-area: actions;
            display: grid;
            row-gap: var(--cinemo-sizes-gap-s);
            column-gap: var(--cinemo-sizes-space-xl);
            margin-top: var(--cinemo-sizes-gap-l);

            &--single {
                display: flex;
                justify-content: center;
            }

            @include devices.phone {
                grid-template-areas:
                    'primary'
                    'secondary';
                margin-top: var(--cinemo-sizes-gap-s);

                .bif-components-button {
                    width: 100%;
                }
            }

            @include devices.tablet-portrait-up {
                grid-template-areas: 'secondary primary';
            }

            .bif-components-button--type-primary {
                grid-area: primary;
            }

            .bif-components-button--type-secondary {
                grid-area: secondary;
            }
        }

        &__close {
            position: absolute;
            top: 3.4rem;
            right: 2.4rem;

            @include devices.phone {
                display: none;
            }

            @include devices.tablet-landscape-up {
                top: 2.6rem;
                right: 3.2rem;
            }
        }
    }
}
